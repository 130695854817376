import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Content from '../Content';

const lyonsRoadTrashCoords = { lat: 42.9095394, lng: -76.9785613 };

const residentialTrashCoords = [
  { lat: 43.094854, lng: -76.857157 }, // Clyde
  { lat: 43.0236736, lng: -76.9973333 }, // Alloway
  { lat: 42.9589201, lng: -77.0693517 }, // Phelps
  { lat: 42.8870088, lng: -77.1048383 }, // Seneca Castle
  { lat: 42.82007, lng: -77.106384 }, // Stanley
  { lat: 42.7570121, lng: -77.0234441 }, // Bellona
  { lat: 42.9051791, lng: -76.8768775 }, // Waterloo
];

const rollOffServiceCoords = [
  { lat: 42.9077582, lng: -76.8134034 }, // Seneca Falls
  { lat: 43.094854, lng: -76.857157 }, // Clyde
  { lat: 43.1415521, lng: -77.2138555 }, // Marion
  { lat: 42.8902246, lng: -77.297047 }, // Canandaigua
  { lat: 42.5986796, lng: -77.1626171 }, // Branchport
  { lat: 42.6610667, lng: -77.0781296 }, // Penn Yan
  { lat: 42.6836414, lng: -76.9608693 }, // Dresden
  { lat: 42.6139326, lng: -76.8312772 }, // Lodi
];

const AboutPageTemplate = ({
  meta_title,
  meta_description,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  const markerRef = React.useRef(null);

  function lyonsRoadTrashMarker(map, maps) {
    let marker = new maps.Marker({
      map: map,
      draggable: true,
      animation: maps.Animation.DROP,
      position: lyonsRoadTrashCoords,
    });

    markerRef.current = marker;

    marker.addListener(
      'click',
      showInfoWindow(
        new maps.InfoWindow(),
        map,
        `2595 Lyons Rd<br>Geneva, NY, 14456<br>(315) 789-2979`
      )
    );
  }

  const handleApiLoaded = (map, maps) => {
    lyonsRoadTrashMarker(map, maps);

    let residentialTrashPolygon = new maps.Polygon({
      paths: residentialTrashCoords,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    });

    let rollOffServicePolygon = new maps.Polygon({
      // paths: [outerCoords, innerCoords] puts a hole in polygon
      // so click events work for residential
      paths: [rollOffServiceCoords, residentialTrashCoords],
      strokeColor: '#004400',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#004400',
      fillOpacity: 0.25,
    });

    let residentialTrashContent = `<b>Residental Trash Pickup</b><br>Servicing Clyde, Alloway, Phelps, Seneca Castle, and Waterloo.`;
    residentialTrashPolygon.addListener(
      'click',
      showInfoWindow(new maps.InfoWindow(), map, residentialTrashContent)
    );

    let rollOffServiceContent = `<b>Roll-Off Service</b><br>Delivering to Seneca Falls, Clyde, Marion, Canandaigua, Branchport, Penn Yan, Dresden, and Lodi.`;
    rollOffServicePolygon.addListener(
      'click',
      showInfoWindow(new maps.InfoWindow(), map, rollOffServiceContent)
    );

    residentialTrashPolygon.setMap(map);
    rollOffServicePolygon.setMap(map);
  };

  const showInfoWindow = (infoWindow, map, contentString) => e => {
    infoWindow.setContent(contentString);
    infoWindow.setPosition(e.latLng);
    infoWindow.open(map);
  };

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
      </Helmet>
      <section className="hero is-medium" style={{ height: '50vh' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyCYpvrPeSAeN7P52E9dDhYrvCdjnBFSSmw',
          }}
          defaultCenter={lyonsRoadTrashCoords}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <div
            lat={lyonsRoadTrashCoords.lat}
            lng={lyonsRoadTrashCoords.lng}
            className="map-marker"
            style={{ color: '#000' }}
          >
            Lyons Road Trash
          </div>
        </GoogleMapReact>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

export default AboutPageTemplate;
